import React from 'react';

const SpotifyIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.66 0 12 0zm5.521 17.34c-.24.371-.721.49-1.101.241-3.021-1.858-6.832-2.278-11.302-1.247-.435.101-.861-.21-.961-.645-.101-.435.21-.861.645-.961 4.91-1.121 9.181-.621 12.598 1.51.371.23.49.721.241 1.101zm1.472-3.272c-.301.461-.921.621-1.381.301-3.462-2.131-8.732-2.751-12.834-1.511-.502.15-1.021-.15-1.171-.645-.15-.502.15-1.021.645-1.171 4.681-1.421 10.492-.721 14.474 1.741.431.3.591.91.271 1.381l-.004-.096zm.127-3.402C15.353 8.407 8.91 8.197 5.208 9.327c-.6.18-1.231-.18-1.411-.78-.18-.6.18-1.231.78-1.411 4.291-1.291 11.433-1.041 15.954 1.601.54.33.72 1.041.39 1.591-.33.54-1.041.72-1.591.39z" />
  </svg>
);

export default SpotifyIcon;
