import React from 'react';
import AdUnit from './AdUnit';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <h1>Privacy Policy</h1>
      
      {/* Ad unit at the top */}
      <AdUnit />

      <div className="policy-content">
        <section>
          <h2>1. Information We Collect</h2>
          <p>We collect information that you provide directly to us when using our Podcast Explorer application.</p>
        </section>

        <section>
          <h2>2. How We Use Your Information</h2>
          <p>We use the information we collect to provide and improve our services, including personalized podcast recommendations.</p>
        </section>

        <section>
          <h2>3. Advertising</h2>
          <p>Our website uses Google AdSense to display advertisements. Google AdSense may use cookies and web beacons to serve ads based on your prior visits to our website or other websites.</p>
        </section>

        <section>
          <h2>4. Third-Party Services</h2>
          <p>We use Spotify's API to provide podcast content. Please refer to Spotify's privacy policy for information about how they handle your data.</p>
        </section>

        {/* Ad unit in the middle */}
        <AdUnit />

        <section>
          <h2>5. Data Security</h2>
          <p>We implement reasonable security measures to protect your information from unauthorized access or disclosure.</p>
        </section>

        <section>
          <h2>6. Changes to Privacy Policy</h2>
          <p>We may update this privacy policy from time to time. We will notify you of any changes by posting the new policy on this page.</p>
        </section>

        <section>
          <h2>7. Contact Us</h2>
          <p>If you have any questions about this privacy policy, please contact us.</p>
        </section>
      </div>

      {/* Ad unit at the bottom */}
      <AdUnit />
    </div>
  );
};

export default PrivacyPolicy;
