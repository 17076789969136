import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import AdUnit from './components/AdUnit';
import SpotifyIcon from './components/SpotifyIcon';
import PrivacyPolicy from './components/PrivacyPolicy';
import './App.css';

const COLORS = [
  '#00C49F', // Green
  '#FF8042', // Orange
  '#FFBB28', // Yellow
  '#0088FE', // Blue
  '#8884d8', // Purple
  '#82ca9d', // Light green
  '#ffc658', // Light orange
  '#8dd1e1', // Light blue
  '#a4de6c', // Lime
  '#d0ed57'  // Yellow green
];

function App() {
  const [topPodcasts, setTopPodcasts] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [episodesChart, setEpisodesChart] = useState([]);
  const [ratingsChart, setRatingsChart] = useState([]);
  const [followersChart, setFollowersChart] = useState([]);
  const [selectedPodcast, setSelectedPodcast] = useState(null);
  const [podcastEpisodes, setPodcastEpisodes] = useState([]);
  const [error, setError] = useState(null);
  const [accessToken, setAccessToken] = useState('');
  const [podcastLimit, setPodcastLimit] = useState(10);
  const [selectedCount, setSelectedCount] = useState(10);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'desc' });
  const [darkMode, setDarkMode] = useState(() => {
    // Get initial theme from localStorage or system preference
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme !== null) {
      return savedTheme === 'dark';
    }
    // If no saved preference, check system preference
    return window.matchMedia('(prefers-color-scheme: dark)').matches;
  });
  const [isRTL, setIsRTL] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);

  const modalRef = useRef(null);

  useEffect(() => {
    // Check if the browser's language is RTL (e.g., Arabic, Hebrew)
    const rtlLanguages = ['ar', 'he', 'fa'];
    const browserLang = navigator.language.split('-')[0];
    setIsRTL(rtlLanguages.includes(browserLang));
  }, []);

  useEffect(() => {
    document.body.className = darkMode ? 'dark-mode' : '';
    // Save dark mode preference to localStorage
    localStorage.setItem('theme', darkMode ? 'dark' : 'light');
    // Set RTL direction on the App container
    document.querySelector('.App').setAttribute('dir', isRTL ? 'rtl' : 'ltr');
  }, [darkMode, isRTL]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setSelectedPodcast(null);
      }
    };

    if (selectedPodcast) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectedPodcast]);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = (e) => {
      if (localStorage.getItem('theme') === null) {
        setDarkMode(e.matches);
      }
    };
    
    mediaQuery.addListener(handleChange);
    return () => mediaQuery.removeListener(handleChange);
  }, []);

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div className="custom-tooltip">
          <p className="podcast-name">{data.name}</p>
          <p className="episode-count">{data.value} {data.label || 'episodes'}</p>
          <p className="publisher">by {data.publisher}</p>
          <p className="rating">Rating: {data.rating}/5.0</p>
        </div>
      );
    }
    return null;
  };

  useEffect(() => {
    const getAccessToken = async () => {
      try {
        const clientId = process.env.REACT_APP_SPOTIFY_CLIENT_ID;
        const clientSecret = process.env.REACT_APP_SPOTIFY_CLIENT_SECRET;
        
        const tokenResponse = await axios.post(
          'https://accounts.spotify.com/api/token',
          new URLSearchParams({
            grant_type: 'client_credentials'
          }).toString(),
          {
            headers: {
              'Authorization': `Basic ${btoa(`${clientId}:${clientSecret}`)}`,
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          }
        );

        setAccessToken(tokenResponse.data.access_token);
        return tokenResponse.data.access_token;
      } catch (error) {
        console.error('Error getting access token:', error);
        setError('Failed to authenticate with Spotify');
        return null;
      }
    };

    const fetchPodcastData = async (token) => {
      try {
        const response = await axios.get('https://api.spotify.com/v1/search', {
          params: {
            q: 'podcast',
            type: 'show',
            market: 'US',
            limit: podcastLimit,
            include_external: 'audio'
          },
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (response.data.shows && response.data.shows.items) {
          // Add random ratings and views since Spotify API doesn't provide these
          const podcasts = response.data.shows.items.map(podcast => ({
            ...podcast,
            rating: (Math.random() * (5 - 4) + 4).toFixed(1), // Random rating between 4.0 and 5.0
            views: Math.floor(Math.random() * (1000000 - 10000) + 10000), // Random views between 10k and 1M
          }));

          // Sort podcasts by rating (highest first)
          const sortedPodcasts = podcasts.sort((a, b) => b.rating - a.rating);
          setTopPodcasts(sortedPodcasts);
          
          // Create chart data for episodes
          const episodesData = sortedPodcasts.map(podcast => ({
            name: podcast.name,
            value: podcast.total_episodes,
            publisher: podcast.publisher,
            rating: podcast.rating,
            label: 'episodes'
          }));
          setEpisodesChart(episodesData);

          // Create chart data for ratings
          const ratingsData = sortedPodcasts.map(podcast => ({
            name: podcast.name,
            value: Number(podcast.rating),
            publisher: podcast.publisher,
            rating: podcast.rating,
            label: 'rating'
          }));
          setRatingsChart(ratingsData);

          // Create chart data for followers (using random data as Spotify API doesn't provide this)
          const followersData = sortedPodcasts.map(podcast => ({
            name: podcast.name,
            value: podcast.views,
            publisher: podcast.publisher,
            rating: podcast.rating,
            label: 'followers'
          }));
          setFollowersChart(followersData);
        }
      } catch (error) {
        console.error('Error fetching podcast data:', error);
        setError(error.response?.data?.message || 'Failed to fetch podcast data');
      }
    };

    const initializeApp = async () => {
      const token = await getAccessToken();
      if (token) {
        await fetchPodcastData(token);
      }
    };

    initializeApp();
  }, [podcastLimit]);

  const handleSearch = async (e) => {
    e.preventDefault();
    if (!searchQuery.trim()) return;

    try {
      const response = await axios.get('https://api.spotify.com/v1/search', {
        params: {
          q: searchQuery,
          type: 'show',
          market: 'US',
          limit: podcastLimit,
          include_external: 'audio'
        },
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });
      
      if (response.data.shows && response.data.shows.items) {
        // Add random ratings and views
        const podcasts = response.data.shows.items.map(podcast => ({
          ...podcast,
          rating: (Math.random() * (5 - 4) + 4).toFixed(1),
          views: Math.floor(Math.random() * (1000000 - 10000) + 10000),
        }));

        // Sort by rating
        const sortedPodcasts = podcasts.sort((a, b) => b.rating - a.rating);
        setSearchResults(sortedPodcasts);
        setTopPodcasts(sortedPodcasts);
        
        // Create chart data for episodes
        const episodesData = sortedPodcasts.map(podcast => ({
          name: podcast.name,
          value: podcast.total_episodes,
          publisher: podcast.publisher,
          rating: podcast.rating,
          label: 'episodes'
        }));
        setEpisodesChart(episodesData);

        // Create chart data for ratings
        const ratingsData = sortedPodcasts.map(podcast => ({
          name: podcast.name,
          value: Number(podcast.rating),
          publisher: podcast.publisher,
          rating: podcast.rating,
          label: 'rating'
        }));
        setRatingsChart(ratingsData);

        // Create chart data for followers (using random data as Spotify API doesn't provide this)
        const followersData = sortedPodcasts.map(podcast => ({
          name: podcast.name,
          value: podcast.views,
          publisher: podcast.publisher,
          rating: podcast.rating,
          label: 'followers'
        }));
        setFollowersChart(followersData);
      }
    } catch (error) {
      console.error('Error searching podcasts:', error);
      setError(error.response?.data?.message || 'Failed to search podcasts');
    }
  };

  const handlePodcastClick = async (podcast) => {
    try {
      const response = await axios.get(`https://api.spotify.com/v1/shows/${podcast.id}/episodes`, {
        params: {
          market: 'US',
          limit: 20
        },
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });

      // Add random view counts to episodes
      const episodesWithViews = response.data.items.map(episode => ({
        ...episode,
        views: Math.floor(Math.random() * (500000 - 5000) + 5000), // Random views between 5k and 500k
      }));

      setSelectedPodcast(podcast);
      setPodcastEpisodes(episodesWithViews);
    } catch (error) {
      console.error('Error fetching episodes:', error);
      setError('Failed to fetch episodes');
    }
  };

  const renderPodcastDetails = () => {
    if (!selectedPodcast) return null;

    return (
      <div className="podcast-details-modal">
        <div className="podcast-details-content" ref={modalRef}>
          <button className="close-button" onClick={() => setSelectedPodcast(null)}>×</button>
          <div className="podcast-header">
            <h2>{selectedPodcast.name}</h2>
            <div className="podcast-rating">
              <span className="rating-stars">
                {'★'.repeat(Math.floor(selectedPodcast.rating))}
                {'☆'.repeat(5 - Math.floor(selectedPodcast.rating))}
              </span>
              <span className="rating-number">{selectedPodcast.rating}</span>
            </div>
            <div className="podcast-views">
              <span>👁 {selectedPodcast.views.toLocaleString()} total views</span>
            </div>
          </div>
          <img src={selectedPodcast.images?.[0]?.url} alt={selectedPodcast.name} width="70%" />
          <p>{selectedPodcast.description}</p>
          <h3>Episodes</h3>
          <div className="episodes-list">
            {podcastEpisodes.map(episode => (
              <div key={episode.id} className="episode-item">
                <h4>{episode.name}</h4>
                <div className="episode-stats">
                  <span>👁 {episode.views.toLocaleString()} views</span>
                  <span>⏱ {Math.floor(episode.duration_ms / 60000)} min</span>
                  <span>📅 {new Date(episode.release_date).toLocaleDateString()}</span>
                </div>
                <p>{episode.description}</p>
                {episode.external_urls?.spotify && (
                  <a 
                    href={episode.external_urls.spotify} 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="listen-button"
                  >
                    <SpotifyIcon />
                    <span>Listen on Spotify</span>
                  </a>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const sortData = (key) => {
    let direction = 'desc';
    if (sortConfig.key === key && sortConfig.direction === 'desc') {
      direction = 'asc';
    }
    setSortConfig({ key, direction });

    const sortedData = [...topPodcasts].sort((a, b) => {
      let aValue = key === 'rating' ? (parseFloat(a[key]) || 0) : (a[key] || 0);
      let bValue = key === 'rating' ? (parseFloat(b[key]) || 0) : (b[key] || 0);
      
      if (direction === 'asc') {
        return aValue - bValue;
      }
      return bValue - aValue;
    });

    setTopPodcasts(sortedData);
  };

  const getSortIcon = (columnKey) => {
    if (sortConfig.key !== columnKey) {
      return <span className="sort-icon">↕️</span>;
    }
    return sortConfig.direction === 'asc' 
      ? <span className="sort-icon">↑</span> 
      : <span className="sort-icon">↓</span>;
  };

  if (error) {
    return (
      <div className="App">
        <header className="App-header">
          <h1>Spotify Podcast Explorer</h1>
          <AdUnit adSlot="1234567890" adFormat="auto" />
        </header>
        <div className="error-message">
          <h2>Error</h2>
          <p>{error}</p>
          <p>Note: This application requires proper Spotify authentication. Please ensure you have:</p>
          <ol>
            <li>A valid Spotify Developer account</li>
            <li>Proper authentication credentials</li>
            <li>The correct access token</li>
          </ol>
        </div>
      </div>
    );
  }

  return (
    <div className={`App ${darkMode ? 'dark-mode' : ''}`}>
      {showPrivacyPolicy ? (
        <PrivacyPolicy />
      ) : (
        <>
          <header className="App-header">
            <div className="header-content">
              <div className="logo-container">
                <h1>
                  <span className="spotify-text">Spotify</span>
                  <span className="podcast-text">Podcast</span>
                  <span className="explorer-text">Explorer</span>
                </h1>
                <p className="app-subtitle">Your smart companion for discovering trending and top-rated podcasts worldwide</p>
              </div>
              <button 
                className="theme-toggle"
                onClick={() => setDarkMode(!darkMode)}
                aria-label="Toggle dark mode"
              >
                {darkMode ? '☀️' : '🌙'}
              </button>
            </div>
            <AdUnit adSlot="1234567890" adFormat="auto" />
          </header>

          <AdUnit />

          <main>
            <section className="search-section">
              <div className="search-controls">
                <form onSubmit={handleSearch}>
                  <input
                    type="text"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="Search for podcasts..."
                  />
                  <div className="limit-selector">
                    <select
                      value={podcastLimit}
                      onChange={(e) => setPodcastLimit(Number(e.target.value))}
                      className="podcast-limit"
                    >
                      <option value={10}>10 Podcasts</option>
                      <option value={20}>20 Podcasts</option>
                      <option value={30}>30 Podcasts</option>
                    </select>
                  </div>
                  <button type="submit">Search</button>
                </form>
              </div>
              {/* Horizontal ad below search */}
              <div className="ad-container-horizontal">
                <AdUnit adSlot="1234567890" adFormat="horizontal" />
              </div>
            </section>

            <section className="top-podcasts">
              <div className="section-header">
                <h2>
                  {searchResults.length > 0 ? (
                    <span className="title-text">Search Results</span>
                  ) : (
                    <span className="title-text">Top {podcastLimit} Podcasts</span>
                  )}
                </h2>
                <div className="header-underline"></div>
              </div>
              <div className="podcast-grid">
                {topPodcasts.map(podcast => (
                  <div key={podcast.id} className="podcast-card" onClick={() => handlePodcastClick(podcast)}>
                    <div className="podcast-image-container">
                      <img src={podcast.images?.[0]?.url} alt={podcast.name} />
                      <div className="podcast-rating-badge">
                        <div className="rating-value">{podcast.rating}</div>
                        <div className="rating-stars">
                          {'★'.repeat(Math.floor(podcast.rating))}
                          {'☆'.repeat(5 - Math.floor(podcast.rating))}
                        </div>
                      </div>
                    </div>
                    <div className="podcast-info">
                      <h3>{podcast.name}</h3>
                      <div className="podcast-views">
                        <span>👁 {podcast.views.toLocaleString()} views</span>
                      </div>
                      <p className="podcast-description">{podcast.description}</p>
                      <div className="podcast-stats">
                        <span>📝 {podcast.total_episodes} episodes</span>
                        <span>👤 {podcast.publisher}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </section>

            <div className="charts-container">
              <section className="chart-section">
                <h2>Episodes per Podcast</h2>
                <div style={{ width: '100%', height: 280 }}>
                  <ResponsiveContainer>
                    <PieChart>
                      <Pie
                        data={episodesChart}
                        cx="50%"
                        cy="50%"
                        innerRadius={60}
                        outerRadius={120}
                        paddingAngle={2}
                        dataKey="value"
                      >
                        {episodesChart.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                      <Tooltip 
                        content={<CustomTooltip />}
                        wrapperStyle={{ backgroundColor: 'white', border: '1px solid #ccc', borderRadius: '8px', padding: '10px' }}
                      />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              </section>

              <section className="chart-section">
                <h2>Podcast Ratings</h2>
                <div style={{ width: '100%', height: 280 }}>
                  <ResponsiveContainer>
                    <PieChart>
                      <Pie
                        data={ratingsChart}
                        cx="50%"
                        cy="50%"
                        innerRadius={60}
                        outerRadius={120}
                        paddingAngle={2}
                        dataKey="value"
                      >
                        {ratingsChart.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                      <Tooltip 
                        content={<CustomTooltip />}
                        wrapperStyle={{ backgroundColor: 'white', border: '1px solid #ccc', borderRadius: '8px', padding: '10px' }}
                      />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              </section>
            </div>

            <section className="podcast-table-section">
              <h2>Detailed Podcast Information</h2>
              <table className="podcast-table">
                <thead>
                  <tr>
                    <th>Color</th>
                    <th>Podcast Name</th>
                    <th>Publisher</th>
                    <th className="sortable" onClick={() => sortData('total_episodes')}>
                      Episodes {getSortIcon('total_episodes')}
                    </th>
                    <th className="sortable" onClick={() => sortData('rating')}>
                      Rating {getSortIcon('rating')}
                    </th>
                    <th>Language</th>
                    <th>Explicit</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {topPodcasts.map((podcast, index) => {
                    const rating = podcast.rating ? parseFloat(podcast.rating) : 0;
                    
                    return (
                      <tr key={podcast.id}>
                        <td>
                          <div 
                            className="color-indicator" 
                            style={{ backgroundColor: COLORS[index % COLORS.length] }}
                          />
                        </td>
                        <td>
                          <div className="podcast-name-cell">
                            <img 
                              src={podcast.images?.[2]?.url || podcast.images?.[0]?.url} 
                              alt={podcast.name}
                              className="podcast-thumbnail"
                            />
                            <span>{podcast.name}</span>
                          </div>
                        </td>
                        <td>{podcast.publisher}</td>
                        <td>{podcast.total_episodes || 0}</td>
                        <td>{rating.toFixed(1)}</td>
                        <td>{podcast.languages?.[0]?.toUpperCase() || 'N/A'}</td>
                        <td>
                          <span className={`explicit-tag ${podcast.explicit ? 'explicit-yes' : 'explicit-no'}`}>
                            {podcast.explicit ? 'Yes' : 'No'}
                          </span>
                        </td>
                        <td>
                          <a 
                            href={podcast.external_urls?.spotify} 
                            target="_blank" 
                            rel="noopener noreferrer"
                            className="spotify-link"
                          >
                            <SpotifyIcon /> Open
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </section>
          </main>

          <AdUnit />

          {renderPodcastDetails()}
        </>
      )}

      <footer className="footer">
        <div className="footer-links">
          <a href="#" onClick={(e) => {
            e.preventDefault();
            setShowPrivacyPolicy(!showPrivacyPolicy);
          }}>
            {showPrivacyPolicy ? 'Back to Home' : 'Privacy Policy'}
          </a>
          <a href="https://www.spotify.com" target="_blank" rel="noopener noreferrer">Spotify</a>
        </div>
        <p>&copy; {new Date().getFullYear()} Podcast Explorer. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;
